var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-finish"},[_c('section',{staticClass:"section booking",attrs:{"id":"section-booking"}},[(_vm.cart && Object.keys(_vm.cart).length > 0)?_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"voffset-90"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row "},[_c('div',{staticClass:"col-lg-12",attrs:{"data-aos-id":"booking-section"}},[(
                                    Object.keys(_vm.simpleData).length < 1 ||
                                        (_vm.simpleData &&
                                            _vm.simpleData.result &&
                                            _vm.simpleData.result.e ===
                                                'SUCCESS')
                                )?_c('h4',{staticClass:"white title"},[_vm._v(" Megkaptuk a rendelésed ")]):_c('h4',{staticClass:"white title"},[_vm._v(" Hiba a megrendelés során! ")])])]),(
                            Object.keys(_vm.simpleData).length < 1 ||
                                (_vm.simpleData &&
                                    _vm.simpleData.result &&
                                    _vm.simpleData.result.e === 'SUCCESS')
                        )?_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-6"},[_c('b',[_vm._v("Rendelési adatok:")]),_c('br'),_vm._v(" Rendelési azonosító: #"+_vm._s(_vm.cart.id)),_c('br'),_vm._v(" Rendelés leadásának ideje: "+_vm._s(_vm.cart.created_at)+" ")]),_c('div',{staticClass:"col-md-6"},[_c('b',[_vm._v("Szállítási cím:")]),_c('br'),_vm._v(" "+_vm._s(_vm.cart.shipping_lastname)+" "+_vm._s(_vm.cart.shipping_firstname)+", "+_vm._s(_vm.cart.shipping_city_name)+" "+_vm._s(_vm.cart.shipping_address)+" ")])]):_vm._e(),_c('div',{staticClass:"voffset-90"}),(_vm.simpleData && _vm.simpleData.result_formatted)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.simpleData.result_formatted)}})])]):_vm._e()]),_c('div',{staticClass:"voffset-90"}),_c('div',{staticClass:"voffset-90"}),_c('div',{staticClass:"voffset-90"})])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('p',[_vm._v(" E-mailben elküldtük a rendelés részleteit. ")])])}]

export { render, staticRenderFns }