<template>
    <div class="payment-finish">
        <section id="section-booking" class="section booking">
            <div
                class="row justify-content-md-center"
                v-if="cart && Object.keys(cart).length > 0"
            >
                <div class="col-12">
                    <div class="voffset-90"></div>
                    <div class="container">
                        <div class="row ">
                            <div
                                class="col-lg-12"
                                data-aos-id="booking-section"
                            >
                                <h4
                                    class="white title"
                                    v-if="
                                        Object.keys(simpleData).length < 1 ||
                                            (simpleData &&
                                                simpleData.result &&
                                                simpleData.result.e ===
                                                    'SUCCESS')
                                    "
                                >
                                    Megkaptuk a rendelésed
                                </h4>
                                <h4 class="white title" v-else>
                                    Hiba a megrendelés során!
                                </h4>
                            </div>
                        </div>
                        <div
                            class="row"
                            v-if="
                                Object.keys(simpleData).length < 1 ||
                                    (simpleData &&
                                        simpleData.result &&
                                        simpleData.result.e === 'SUCCESS')
                            "
                        >
                            <div class="col-12">
                                <p>
                                    E-mailben elküldtük a rendelés részleteit.
                                </p>
                            </div>
                            <div class="col-md-6">
                                <b>Rendelési adatok:</b><br />
                                Rendelési azonosító: #{{ cart.id }}<br />
                                Rendelés leadásának ideje: {{ cart.created_at }}
                            </div>
                            <div class="col-md-6">
                                <b>Szállítási cím:</b><br />
                                {{ cart.shipping_lastname }}
                                {{ cart.shipping_firstname }},
                                {{ cart.shipping_city_name }}
                                {{ cart.shipping_address }}
                            </div>
                        </div>
                        <div class="voffset-90"></div>
                        <div
                            class="row"
                            v-if="simpleData && simpleData.result_formatted"
                        >
                            <div class="col">
                                <p v-html="simpleData.result_formatted"></p>
                            </div>
                        </div>
                    </div>
                    <div class="voffset-90"></div>
                    <div class="voffset-90"></div>
                    <div class="voffset-90"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "PaymentFinish",
    data() {
        return {
            cart: {},
            simpleData: {}
        };
    },
    beforeMount() {
        this.getCart();
        this.backref();
    },
    methods: {
        getCart() {
            const cartUuid = this.$route.query.orderId;
            if (cartUuid) {
                this.$http
                    .get(`${this.url}/order/get/${cartUuid}`)
                    .then(response => {
                        this.cart = response.data.data;
                        this.$eventHub.$emit("updateCart", true);
                    })
                    .finally(() => {
                        $(document).ready(function() {
                            $("#mask").hide();
                        });
                    });
            }
        },
        backref() {
            const self = this;
            if (self.$route.query.r && self.$route.query.s) {
                self.axios
                    .post(`${self.url}/order/backref`, self.$route.query)
                    .then(response => {
                        self.simpleData = response.data;
                        self.cart = response.data.cart;
                        self.$eventHub.$emit("updateCart", true);
                    })
                    .catch(err => {
                        console.log(err);
                        // this.$router.push({ name: 'NotFound' })
                    });
            }
        }
    }
};
</script>

<style scoped></style>
